import React, {Component } from 'react';
import './FifthContaint.css'
import StructureTwo from '../Containts/StructureTwo'

class FifthContaint extends Component {
    render() { 
        return ( 
            <section className="fifth-heghlight-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <StructureTwo wrapperClass="left-side-wrapper" 
                            innerClassName="left-side-container"
                            title= "二手物品" description="出售闲置物品，购入需要的物品"
                            price=""
                            link="Apply now">
                        </StructureTwo>
                        <StructureTwo wrapperClass="right-side-wrapper"
                            innerClassName="right-side-container" titleColor="white" linkColor="white"
                            title="呼朋唤友，找搭子" description="烧烤，喝酒，逛街聊天">
                        </StructureTwo>
                    </div>
                </div> 
	        </section>
         );
    }
}
 
export default FifthContaint;