import React from 'react';
import './FooterBa.css';
// 备案信息组件
const Footer = () => {
    return (
        <footer className='footer'>
            <p className='footer-text'>
                <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer" className="footer-link">豫ICP备2024043564号-1</a>
            </p>
        </footer>
    );
};

export default Footer;
