import React, {Component } from 'react';
import Structure from "../Containts/Structure";
import './ThirdContaint.css'

class ThirdContaint extends Component {
   
    render() { 
        return ( 
            <section className="third-hightlight-wrapper">
               <Structure titleColor= "white" title="发布需求" priceColor ="grey" 
               price = ""
			    colorClass="white" description="出售闲置书籍、专业技能"
				/>
	        </section>
         );
    }
}
 
export default ThirdContaint;

