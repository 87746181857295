import React, {Component } from 'react';
import Structure from "../Containts/Structure"
import './SecContaints.css';

class SecondContaints extends Component {
    
    render() { 
        return ( 
            <section className="second-hightlight-wrapper">
				<Structure  alert="目标功能" titleColor="black" title="共享物品" priceColor ="grey" price = ""
				colorClass="black" description="邻近地区的人们可以共享他们的物品，例如工具、闲置物品等等。"
				/>
				
	        </section>
         );
    }
}
export default SecondContaints;